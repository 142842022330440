import './TabHome.css';
import TabBottom from "../bottom/TabBottom";
import TabTop from "../top/TabTop";
import Helmet from "react-helmet";
import DownloadPlayBar from "./DownloadPlayBar";

export default function TabHome() {

    return <div className={"TabHome"}>
        <Helmet>
            <meta name={'description'} content={'Lucky 3 Patti provides TeenPatti Lucky, Rummy and many other Indian card games, 3Patti India free online cash games are now available for free download, want to play Indian free online cash games, come and experience it at lucky 3Patti!'} />
            <title>Lucky Rummy 3 Patti - Indian Card Game│TeenPatti Lucky</title>
        </Helmet>
        <TabTop idx={0}/>

        <div className={"Content1"}>
            <img className={'Content1Img'}
                 src={require("../../../assets/img/bg2.png")}
                 alt={"download India free online cash game"}
                 onDragStart={(e) => e?.preventDefault()}>
            </img>
            <DownloadPlayBar />
        </div>

        <div className={"Content2"}>

        </div>

        <div className={"Content3"}>

        </div>

        <div className={"Content4"}>

        </div>

        <TabBottom/>
    </div>
}
