import TabHome from "./home/TabHome";
import TabHotGames from "./home/TabHotGames";
import TabDownload from "./home/TabDownload";
import TabBlog from "./home/TabBlog";
import React from "react";
import {apkUrl, download} from "../../util/Common";
import TabFaq from "./home/TabFaq";

interface TabContentProps {
    tabIdx: number;
}

function TabContainer(props: TabContentProps) {
    switch (props.tabIdx) {
        case 0:
            return <TabHome/>
        case 1:
            return <TabHotGames/>
        case 2:
            // download(apkUrl);
            return <TabDownload/>
        case 3:
            return <TabBlog/>
        case 4:
            return <TabFaq/>
    }
    return <></>;
}

export default TabContainer;
