import './TabBlog.css';
import TabBottom from "../bottom/TabBottom";
import TabTop from "../top/TabTop";
import React, {useState} from "react";
import Blog01 from "./blog/Blog01";
import {apkUrl, download} from "../../../util/Common";
import Helmet from "react-helmet";
import Blog02 from "./blog/Blog02";
import Blog03 from "./blog/Blog03";
import Blog04 from "./blog/Blog04";
import Blog05 from "./blog/Blog05";
import Blog06 from "./blog/Blog06";
import Blog07 from "./blog/Blog07";

export default function TabBlog() {
    let content = <></>;
    const [pageIdx, setPageIdx] = useState(-1);

    window.setPageIdx = function (idx: number) {
        window.pageIdx = idx;
        setPageIdx(idx);
    };

    if (pageIdx === -1) {
        content = blogPage(setPageIdx);
    } else if (pageIdx === 1) {
        content = <Blog01/>
    } else if (pageIdx === 2) {
        content = <Blog02/>
    } else if (pageIdx === 3) {
        content = <Blog03/>
    } else if (pageIdx === 4) {
        content = <Blog04/>
    } else if (pageIdx === 5) {
        content = <Blog05/>
    } else if (pageIdx === 6) {
        content = <Blog06/>
    } else if (pageIdx === 7) {
        content = <Blog07/>
    }

    return <div className={"TabBlog"}>
        <TabTop idx={3}/>

        {content}

        <TabBottom/>
    </div>
}

function blogPage(setPageIdx: (idx: number) => void) {
    return <div className={'TabBlog'}>
        <Helmet>
            <meta name={`description`}
                  content={`TeenPatti Lucky Blog , share more ways to play Play India free online cash games.`}/>
            <title>TeenPatti Lucky Blog│lucky 3Patti game tips│TeenPatti Lucky</title>
        </Helmet>

        <img className={'TabBlogPageImg'}
             src={require('../../../assets/img/blog.png')}
             alt={'3patti india free online cash games'}
             onClick={() => {
                 download(apkUrl, true);
             }}/>

        <BlogItem setPageIdx={setPageIdx} idx={7}
                  img={require("../../../assets/blog07/1.png")}
                  path={"india-free-online-cash-games-apk"}
                  title={"The Most Popular India Free Online Cash Games Apk  - Teen Patti Lucky"}
                  desc={"If you are searching for a one-stop online casino that provides you with India’s most popular cash games, you certainly cannot miss the opportunity of downloading India free online cash games Apk, Teen Patti Lucky, where you can enjoy six different games, creating countless joy for you!"}/>

        <BlogItem setPageIdx={setPageIdx} idx={6}
                  img={require("../../../assets/blog06/1.png")}
                  path={"teen-patti-lucky-apk-download"}
                  title={"The Best Game for Card Lovers – Teen Patti Lucky Apk Download"}
                  desc={"Teen Patti Lucky Apk Download awards new users with free chips and has established a VIP club, where participants can enjoy both priority service and unlimited bonuses. Teen Patti Lucky Apk Download total of six games are offered, including Teen Patti, Teen Patti War, Rummy, Andar Bahar, Dragon Tiger, and Jhandi Munda. The large variety of games allows you to continuously gain new gaming experiences and elevate your skills from various perspectives."}/>

        <BlogItem setPageIdx={setPageIdx} idx={5}
                  img={require("../../../assets/blog05/1.png")}
                  path={"3patti-india-free-online-cash-games"}
                  title={"The Best Tips and Tricks of 3Patti India Free Online Cash Games for Beginners"}
                  desc={"Teen Patti is a game involving both luck and tactics. By mastering the above tips and tricks, you are able to maximize your chance of winning. If you are searching for a platform offering 3Patti India free online cash games and wish to test out your ability, you can visit the website of Teen Patti Lucky and compete with numerous excellent players all around the world with only a few clicks on your phone!"}/>

        <BlogItem setPageIdx={setPageIdx} idx={4}
                  img={require("../../../assets/blog04/1.png")}
                  path={"play-india-free-online-cash-games"}
                  title={"Play India Free Online Cash Games – General FAQ"}
                  desc={"If you are eager to play India free online cash games and looking for a way to join the online casino, you can simply visit the website of your ideal platform and fill in the personal information required, in particular your name, email account, and financial information. After fulfilling all the steps, you can immediately start your journey in the games."}/>

        <BlogItem setPageIdx={setPageIdx} idx={3}
                  img={require("../../../assets/blog03/1.png")}
                  path={"download-free-online-cash-games-in-india"}
                  title={"The Pros and Cons of Downloading Free Online Cash Games in India"}
                  desc={"The advantages and disadvantages of downloading free online cash games in India are that it has thousands of different online gambling applications"}/>

        <BlogItem setPageIdx={setPageIdx} idx={2}
                  img={require("../../../assets/blog02/1.png")}
                  path={"play-luck-rummy-3Patti"}
                  title={"Play Luck Rummy 3 Patti – Comparison between Rummy and Teen Patti"}
                  desc={"If you are a newcomer to online card games and wonder about the differences between these two games, you can find your answer here!"}/>

        <BlogItem setPageIdx={setPageIdx} idx={1}
                  img={require("../../../assets/blog/blog1.png")}
                  path={"THE-BEST-INDIAN-RUMMY-ONLINE-CASH-GAMES---ONLY-ON-TEEN-PATTI-LUCKY"}
                  title={"THE BEST INDIAN RUMMY ONLINE CASH GAMES - ONLY ON TEEN PATTI LUCKY"}
                  desc={"Downloading Indian poker online game is a modern way to enjoy"}/>

    </div>
}

interface BlogItemProps {
    setPageIdx: (idx: number) => void;
    idx: number;

    title: string;

    path: string;
    desc: string;

    img: any;
}

function BlogItem(props: BlogItemProps) {
    return <div className={'BlogItem'} onClick={() => {
        props.setPageIdx(props.idx);
        document.getElementById('HomeBody')!.scrollTop = 0;
    }}>
        <div className={'BlogItemImg'} style={{
            backgroundImage: `url(${props.img})`,
        }}/>
        <div className={"BlogItemContent"}>
            <div className={'BlogItemTitle'}>{props.title}</div>
            <div className={'BlogItemDesc'}>{props.desc}</div>
            <div className={'BlogItemReadMore'}>Read More&gt;</div>
        </div>
    </div>
}
