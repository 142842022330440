import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import ErrorBoundary from "./util/ErrorBoundary";
import EventEmitter from "events";
import {HomeWrapper} from "./page/HomeWrapper";

try {
    require("./generator/ver").ver();
} catch (e) {
}

export const eventBus = new EventEmitter();

// Adjust.initSdk({
//     appToken: 'rpc16i3vog00',
//     environment: 'production'
// });

(async () => {
    const xhr = new XMLHttpRequest()
    xhr.timeout = 15000
    xhr.open('GET', 'https://view.adjust.com/impression/wie00nj?redirect=https%3A%2F%2Fd1sskg649l0paa.cloudfront.net%2Fland%2Flucky2%2Fslot.html%3Fll%3Dhttps://d1sskg649l0paa.cloudfront.net/lucky/810001.apk')
    xhr.send()
    xhr.onload = function () {
    }
    xhr.onerror = function (error) {
    }
})()

let search = window.location.search;
if (!search || search.length === 0) {
    search = localStorage.getItem('urlParams') ?? ''
} else {
    localStorage.setItem('urlParams', search)
}
window.urlParams = new URLSearchParams(search);

window.onload = function() {
    setInterval(() => {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            window.location.replace('game.html');
        }
    }, 200)
}
window.deferredPrompt = null;
window.addEventListener('beforeinstallprompt',async (e) => {
    window.deferredPrompt = e;
});

window.addEventListener('appinstalled', () => {
    window.localStorage.setItem('appinstalled', 'true');
});


ReactDOM.render(
    <React.StrictMode>
        <ErrorBoundary>
            <HomeWrapper/>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);
