import {apkUrl, download} from "../../../util/Common";
import './DownloadPlayBar.css';

export default function DownloadPlayBar() {
    return <div className={"DownloadPlayBar"}>
        <div className={"PlayNowBtnS"} onClick={ async () => {
            try {
                if("getInstalledRelatedApps" in navigator) {
                    if (window.localStorage.getItem('appinstalled') == 'true') {
                        // window.location.href = 'https://lucky3patti.com/game.html';
                        window.open('https://lucky3patti.com/game.html', '_blank')
                    } else if (window.deferredPrompt != null) {
                        window.deferredPrompt.prompt();
                        const { choice } = await window.deferredPrompt.userChoice;
                        console.log(choice);
                        if (choice === 'accepted') {
                            window.deferredPrompt = null;
                        }
                    }
                } else {
                    window.location.href = window.urlParams.get("ll") ?? "https://d1sskg649l0paa.cloudfront.net/lucky/game/v1/index.html?channelId=500001"
                }
            } catch (e) {

            }

        }}></div>
        <div className={"DownloadBtnS"} onClick={() => {
            download(apkUrl, true);
        }}></div>
    </div>
}
