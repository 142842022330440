import './TabDownload.css';
import TabBottom from "../bottom/TabBottom";
import TabTop from "../top/TabTop";
import Helmet from "react-helmet";

export default function TabDownload() {


    return <div className={"TabDownload"}>
        <Helmet>
            <meta name={'description'} content={'Free download India free online cash games apk, start playing Teen Patti Lucky!'} />
            <title>Teen Patti Lucky Apk Download│TeenPatti Lucky</title>
        </Helmet>

        <TabTop idx={2}/>

        <div className={"ContentDownload"}>
            <img className={'ContentDownloadImg'}
                 src={require('../../../assets/download/Click to restart@2x.png')}
                 alt={'teen patti lucky apk download'}
                 onDragStart={(e) => e?.preventDefault()}/>

            <div className={'HowToInstallImg'}></div>

            <h3 className={'Step1Text'}>STEP1:Download Teen Patti Lucky</h3>
            <article className={'Step2Text'}>Click "OK" on Chrome, then click the Download complete notification after download. There may be warnings while you downloading an APK file on Chrome. Click 〝OK〞to continue. Teen Patti Lucky is safe, thanks for your trusts.</article>

            <div className={'StepRow'}>
                <img alt={'TeenPatti Lucky'} className={'StepImg'} src={require('../../../assets/download/lucky3patti800001.apk@2x.png')}></img>
                <img alt={'TeenPatti Lucky'} className={'StepImg'} src={require('../../../assets/download/lucky3patti800001.apk@2x(1).png')}></img>
            </div>

            <h3 className={'Step3Text'}>STEP2:Switch on unknow sources</h3>
            <article className={'Step4Text'}>Click settings then switch on "unknown sources" You need to switch on the options for installing APP out of Google Play.</article>

            <div className={'StepRow'}>
                <img alt={'TeenPatti Lucky'} className={'StepImg'} src={require('../../../assets/download/图层 4@2x.png')}></img>
                <img alt={'TeenPatti Lucky'} className={'StepImg'} src={require('../../../assets/download/图层 5@2x.png')}></img>
            </div>
        </div>

        <TabBottom/>
    </div>
}
