/**
 * @description
 * @author  jochen
 * @since  2022-04-13
 */
import React, {ErrorInfo} from "react";

interface ErrorState {
    hasError: boolean;
    error: any;
    info: any;
}

export default class ErrorBoundary extends React.Component<any, ErrorState> {
    constructor(props: any) {
        super(props);
        this.state = {hasError: false, error: null,info:null};
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
        console.log(error.message)
        console.log(info.componentStack)
        alert(error.message)
        alert(info.componentStack)
        this.setState({hasError: true, error: error,info:info});
    }

    render() {
        if (this.state.hasError) {
            return <div>
                <p>{this.state.error}</p>
                <p>{this.state.info}</p>
            </div>
        }
        return this.props.children;
    }
}
