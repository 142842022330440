import React, {useState} from 'react';
import './Home.scss';
import {LOCALES} from "../i18n/locales";
import {isMobile} from 'react-device-detect';
import {apkUrl, download} from "../util/Common";
import TabContainer from "./content/TabContainer";

export interface HomeProps {
    setLocale: any
}

function Home(props: HomeProps) {
    const [curIdx, changeIdx] = React.useState(0);


    const [loadingOpen, setLoadingOpen] = React.useState(false);
    const [tipsOpen, setTipsOpen] = React.useState(false);
    const [tipsDialogOpen, setTipsDialogOpen] = useState(false);
    const [tipsMsg, setTipsMsg] = React.useState("");
    const [tipsDialogMsg, setTipsDialogMsg] = React.useState("");

    const [lanType, setLanType] = useState(window.languageCode === LOCALES.EN ? 1 : 0);
    const [lanSelectOpen, setLanSelectOpen] = useState(false);

    window.setLoading = (open: boolean) => {
        if (loadingOpen !== open) {
            setLoadingOpen(open);
        }
        window.isLoading = open;
    };

    window.showTips = (msg: string) => {
        setTipsMsg(msg);
        setTipsOpen(true);
    };

    window.showTipsDialog = (msg: string) => {
        setTipsDialogMsg(msg);
        setTipsDialogOpen(true);
    };

    window.changeTab = (idx: number) => {
        changeIdx(idx);
    };

    return (
        <div className="Home">
            <div className={"HomeBody"} id={'HomeBody'} style={{
                width: isMobile ? '100%' : '500px'
            }}>
                <TabContainer tabIdx={curIdx}/>
            </div>
            <div className={"BottomBar"} style={{
                height: isMobile ? '10vh' : '70px'
            }}>
                <div className={"GameIcon"} style={{
                    height: isMobile ? '7vh' : '55px',
                    width: isMobile ? '7vh' : '55px'
                }}></div>
                <div className={"GameDesc"}>
                    <div className={"GameName"}>Play Real Cash Teen Patti game</div>
                    <div className={"GameRemark"} style={{}}>Register and Get FREE Rs.30!
                    </div>
                </div>
                <div className={"GameDownload"} style={{
                    height: isMobile ? '5vh' : '42px',
                    width: isMobile ? '15vh' : '130px'
                }} onClick={() => {
                    download(apkUrl, true);
                }}></div>
            </div>
        </div>
    );
}

export default Home;
