import {apkUrl, download} from "../../../util/Common";
import './TabBottom.css';

export default function TabBottom () {
    return <div className={"TabBottom"}>
        <h2 className={"ContentLink1"}>Download Teen Patti Lucky</h2>
        <div className={"ContentLink2"} onClick={() => {
            download(apkUrl, true);
        }}>Download newst Teen Patti Lucky Apk
        </div>

        <h2 className={"ContentLink1"}>Social Links</h2>
        <a href={'https://www.facebook.com/profile.php?id=100090412089639'} target={'_blank'} className={"ContentLink2"}>Facebook</a>
        <a href={'https://www.youtube.com/@lucky3patti'} target={'_blank'} className={"ContentLink2"}>YouTube</a>

        <div className={"Line"}></div>

        <div className={"Reserved"}>2023 lucky3patti.com, all rights reserved</div>
    </div>
}
