/**
 * @description
 * @author  jochen
 * @since  2022-03-17
 */
export const LOCALES = {
    EN: "en",
    HI: "hi",
    PT_BR: "pt-br",
}
