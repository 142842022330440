import {LOCALES} from "../i18n/locales";

/**
 * @description
 * @author  jochen
 * @since  2022-03-22
 */
export default class Common {

    static async sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve('');
            }, ms)
        });
    }
}

export function download(url: string, click: boolean) {
    if (!click && localStorage.getItem("hasClickDownload")) {
        return;
    }
    let dl = window.urlParams.get("dl")
    if (dl && dl.startsWith("http")) {
        url = dl
    }
    localStorage.setItem("hasClickDownload", "true");
    const eleLink = document.createElement('a');
    eleLink.style.display = 'none';
    eleLink.href = url;
    document.body.appendChild(eleLink);
    eleLink.click();
    document.body.removeChild(eleLink);
}

export let apkUrl = "https://d1sskg649l0paa.cloudfront.net/lucky/lucky3patti800001.apk";

export function getLocalClassName(className: string) {
    let result = className;
    if (window.languageCode === LOCALES.PT_BR) {
        result = className + "PtBr";
    } else if (window.languageCode === LOCALES.HI) {
        result = className + "Hi";
    }
    return result;
}


export function getLocalImageDir() {
    let result = "imgs/";
    if (window.languageCode === LOCALES.PT_BR) {
        result = "imgs_pt_br/";
    } else if (window.languageCode === LOCALES.HI) {
        result = "imgs_hi/";
    }
    return result;
}


export function isNull(str: string) {
    if (str === "" || !str) return true;
    const re = new RegExp("^ +$");
    return re.test(str);
}

export function getItem(key: string) {
    if (!window.localStorage) {
        return ""
    }
    return window.localStorage.getItem(key)
}

export function setItem(key: string, value: string) {
    if (window.localStorage) {
        window.localStorage.setItem(key, value)
    }
}
