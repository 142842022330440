import './TabHotGames.css';
import {apkUrl, download} from "../../../util/Common";
import TabBottom from "../bottom/TabBottom";
import TabTop from "../top/TabTop";
import {isMobile} from "react-device-detect";
import Helmet from "react-helmet";
import DownloadPlayBar from "./DownloadPlayBar";

export default function TabHotGames() {

    return <div className={"TabHotGames"}>
        <Helmet>
            <meta name={`description`}
                  content={`Download Indian Free Online Cash Games, here are the 6 hottest Indian cash games of 2023, download for free!`}/>
            <title>Download the Hottest Indian Online Cash Games for Free│TeenPatti Lucky</title>
        </Helmet>

        <TabTop idx={1}/>

        <div className={'ContentHotGames'} style={{
            fontSize: isMobile ? '0.8rem' : '1rem'
        }}>
            <img className={"ContentHotGamesImg"}
                 alt={"play India free online cash games"}
                 src={require("../../../assets/hot/GAMES@2x.png")}
                 onDragStart={(e) => e?.preventDefault()}
                 onClick={() => {
                     download(apkUrl, false);
                 }}>
            </img>

            <DownloadPlayBar />

            <div className={'HotGamesImg'}></div>

            <div className={'HotGamesRow'}>
                <HotGameItem title={'Teen Patti'} img={require('../../../assets/hot/tp优@2x.png')} desc={'Teen Patti is a india\'s poker game played by 3~6 players. The game is played with 52 cards, excluding the joker.Players bet based on the strength of their cards and their skills.Catch the Trail, win 9999RS+ Instant Cash!'}/>
                <HotGameItem title={'Rummy'} img={require('../../../assets/hot/rummy@2x.png')} desc={'Rummy is amongst the most popular games in India. It’s easy, fun and packed with a healthy dose of challenge that will keep you coming back for more. Rummy becomes a popular Indian online cash game. Show Your Skills, win 10 Lakh! Come on!'}/>

            </div>

            <div className={'HotGamesRow'}>
                <HotGameItem title={'Andar Bahar'} img={require('../../../assets/hot/ab@2x.png')} desc={'Andar Bahar is a simple card game, said to originate in southern India, and has since spread all over India and now Internet casinos.50% Winning Chance. 100% Cash. 100% Thrill!'}/>
                <HotGameItem title={'Dragon Tiger'} img={require('../../../assets/hot/dvt@2x.png')} desc={'Dragon Tiger begins when players bet on either Dragon or Tiger options on the table. Depending on whether you bet on the Dragon or the Tiger box, you win if the highest card appears on that particular option.'}/>
            </div>

            <div className={'HotGamesRow'}>
                <HotGameItem title={'Jhandi Munda'} img={require('../../../assets/hot/jm@2x.png')} desc={'"Jhandi Munda" is traditional betting gambling board game played in India. This game is called as "Langur Burja" in Nepal and also known as "Crown and Anchor" in other part of the world.'}/>
                <HotGameItem title={'TeenPatti War'} img={require('../../../assets/hot/tpw@2x.png')} desc={'This game follows the rules of teen patti\'s card comparison. After each party deals three cards, compare the card types. You can win money by betting on the winner and card types to win 10 lahk!'}/>
            </div>
        </div>

        <TabBottom/>
    </div>
}

interface HotGameItemProp {
    title: string;
    img: any;
    desc: string;
}

export function HotGameItem(prop: HotGameItemProp) {
    return <div className={'HotGameItem'}>
        <img className={'HotGameItemImg'} alt={prop.title} src={prop.img}/>
        <h2 className={'HotGameItemTitle'}>{prop.title}</h2>
        <article className={'HotGameItemDesc'}>{prop.desc}</article>
        <div className={'HotGameItemDownload'}></div>
    </div>
}
