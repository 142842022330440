import './BlogActionBtn.css';

export function BackToTop() {
    return <div className={'BackToTop'} onClick={() => {
        document.getElementById('HomeBody')!.scrollTop = 0;
    }}></div>
}

export function BackToBlog() {
    return <div className={'BackToBlog'} onClick={() => {
        window.setPageIdx(-1);
        document.getElementById('HomeBody')!.scrollTop = 0;
    }}></div>
}

export function NextAndPrevious() {
    return <div className={'NextAndPrevious'}>
        <div className={'PreviousBlog'} onClick={() => {
            window.setPageIdx(window.pageIdx + 1);
            document.getElementById('HomeBody')!.scrollTop = 0;
        }}/>
        <div className={'NextBlog'}  onClick={() => {
            window.setPageIdx(window.pageIdx - 1);
            document.getElementById('HomeBody')!.scrollTop = 0;
        }}/>
    </div>
}
