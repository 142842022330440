/**
 * @description
 * @author  jochen
 * @since  2022-03-17
 */
import {LOCALES} from "./locales";

export const messages = {
    [LOCALES.EN]: {
        "@@locale": "en",
        "confirm": "Confirm",
        "cancel": "Cancel",
        "okay": "OK",
        "click_to_invite": "Click to share:",
        "label_cur_balance": "Current balance",
        "withdraw": "Withdraw",
        "withdrawal": "Withdrawal",
        "wallet_detail": "Details",
        "date": "Date",
        "type": "Type",
        "commission": "Commission",
        "label_total_commission": "Total Commission",
        "toast_error_mobile": "Incorrect Phone Format!",
        "toast_error_ifsc": "Incorrect IFSC Code!",
        "toast_error_opt": "Incorrect verification code!",
        "toast_error_email": "Incorrect Email Format!",
        "my_team": "My Team",
        "my_team_today_commission": "Today's Commission",
        "you_have_got": "You\'ve Got:",
        "currency_symbol": "₹",
        "crazy_invitation": "Crazy Invitation",
        "tab_earn_money": "Earn Money",


        "time_box":"Time box",
        "daily_box":"Daily Box",
        "back":"Back",
        "finish_get_more":"Finish tasks, get more cash!",
        "you_can_get_free_box_at":"You can get a Free box at 21:30",
        "you_can_get_free_box_everyday":"You can get a free box everyday.",
        "share_your_link_facebook":"Share your link once on Facebook",
        "share_your_link_whatsapp":"Share your link once on WhatsApp",
        "invite3_today":"Invite 3 friends today, you can finish it for 3 times today.",
        "you_recharge_more200":"You recharge more than ₹200 today.",
        "get_recharge_from_friends":"Get recharge commissions from friends more than ₹25 today",
        "you_bet_more_than":"You bet more than ₹2000 today.",
        "share_by_facebook":"Share by Facebook",
        "share_by_whatsapp":"Share by WhatsApp",
        "invite3friends":"Invite 3 friends",
        "recharge_task":"Recharging Task",
        "get_recharge_commissions":"Get recharge commissions",
        "betting_task":"Betting Task",
        "your_cash_rewards":"Your cash rewards",
        "opened":"Opened",
        "free_box":"Free Box",
        "sign_in":"Sign in",
        "more_boxes":"More boxes",
        "cash_in_tomorrow":"Cash in tomorrow's free box X",
        "open_1_more_box":"Open 1 more box become X",
        "open":"Open",
        "youve_got":"you've got",
        "clear_all_if_stop":"Clear all if stopped",
        "after_get_all_cash":"After you getting all cash, you can withdraw them all.",
        "earn_cash_everyday":"Earn cash everyday, {days} days left",
        "withdraw_all_cash":"Withdraw all cash",
        "sign_in_get_cash":"Sign in and get cash",
        "continue_tomorrow_clear":"Continue tomorrow, clear all if stopped",
        "get_more_cash":"Get more cash",
        "open_suc":"Congratulations!",
        "sign_in_suc":"Sign in successfully!",

        "cash_box":"Cash Box",
        "no_withdraw_channel":"Withdrawal channel maintenance, just one moment.",
        "message_center": "Message Center",
        "invite_earn_everyday": "Share to your friends<br/> Win Real Cash everyday!",
        "other": "Other",
        "from_affiliate": "From Affiliate",
        "from_referrer": "From Referral",
        "my_money_title": "Show where your commissions come from everyday!",
        "my_money": "My Money",
        "incubating": "Incubating...",
        "no_eggs_now": "No eggs now,",
        "how_about_invite": "How about invite more friends?",
        "oops_you_missed": "Oops, you missed.",
        "choose": "Choose",
        "pending": "Pending...",
        "choose_withdrawal_amount": "Choose Withdrawal Amount",
        "fee": "Fee",
        "new_user_only": "New user only",
        "fee_desc": "The withdrawal fee is charged by the payment channel, and we do not charge any fees.",
        "benefit_level": "Benefit Level",
        "commission_ratio": "Commission Ratio",
        "upgrading_cash": "Upgrading Cash",
        "known_more": "Know more",
        "points": "Points",
        "my_level": "My Level",
        "vip_valid_days": "Affiliate Valid Days",
        "vip_maximum_affiliate": "Maximum Commission Per Affiliate",
        "vip_tips1": "Cash rebate is valid only if your friend recharge within valid days of his registration.",
        "vip_tips2": "The rebate cash by each affiliate cannot exceed this limit.",
        "tips": "TIPS",
        "input_ifsc": "Please enter IFSC Code",
        "input_upi": "Please enter your UPI",
        "input_bank_account": "Please enter your bank account",
        "level1_name": "Bronze Privilege",
        "level2_name": "Silver Privilege",
        "level3_name": "Gold Privilege",
        "level4_name": "Platinum Privilege",
        "level5_name": "Diamond Privilege",
        "total_referrals": "Total Referrals",
        "vip": "VIP",
        "close": "Close",
        "break_me": "Break me",
        "incubating_please_patient": "Incubating, please be patient...",
        "no_record_last_7": "No records in the last 7 days",
        "invite_1_friends_1": "Invite 1 friends = 1",
        "invite_2_friends_2": "Invite 2 friends = 2",
        "others": "Others",
        "crazy_invitation_tips": "You have become an agent now, invite friends to earn more free cash! Here is novice reward for you.",
        "welcome": "Welcome!",
        "egg_how_to_play_title": "What is Golden Egg?",
        "egg_how_to_play_text1": "Q:How Golden Egg works?",
        "egg_how_to_play_text2": "A:Golden Egg helps you earn <span style='color: #1CAA28'>₹{client_egg_hour}</span> per hour automatically. When <span style='color: #1CAA28'>₹{client_egg_total}</span> is collected in egg, you can withdraw all the cash.",
        "egg_how_to_play_text3": "Q:How to get a Golden Egg?",
        "egg_how_to_play_text4": "A:You can get an egg after inviting 2 friends successfully. Thereafter, you can get a golden egg every time you invite 1 friend.",
        "egg_how_to_play_text5": "Q:Why there are two options?",
        "egg_how_to_play_text6": "A:If you choose 1x, your chance of getting the reward is 100%, if you choose 3x, you have a chance of getting 3x the reward or losing all the rewards.",
        "convidar_agora": "INVITE NOW",
        "break": "Break",
        "how_to_play": "How to play?",
        "your_eggs": "Your Eggs:",
        "golden_egg": "Golden Egg",
        "wallet_detail_type_0": "Others",
        "wallet_detail_type_1": "Withdraw",
        "wallet_detail_type_2": "Others",
        "wallet_detail_type_3": "Others",
        "wallet_detail_type_4": "Others",
        "wallet_detail_type_5": "Withdraw Failed",
        "wallet_detail_type_6": "Others",
        "wallet_detail_type_7": "Others",
        "wallet_detail_type_8": "Others",
        "wallet_detail_type_9": "Crazy Invitation Commission",
        "wallet_detail_type_10": "Recharge Commission",
        "wallet_detail_type_11": "Register Commission",
        "wallet_detail_type_12": "Welcome Bonus",
        "wallet_detail_type_13": "Golden Egg",
        "locked": "Locked",
        "recent_7_days": "- Recent 7 days -",
        "referrals_recharged": "Referrals Recharged:",
        "cashback_tips": "Cashback will add into your withdrawal account instanly, the more your friends recharge, the more you get",
        "recharge_cashback": "Recharge Cashback",
        "sign_up_in": "Sign up/in",
        "agent_id_copied": "Agent ID copied successfully.",
        "failed": "failed",
        "copy": "Copy",
        "copy_and_send": "Copy&Send",
        "copied_link_suc": "Copied successfully, now you can share it with your friends.",
        "verification_code_sent_suc": "Verification code has been sent, please check it in your email or mobile phone.",
        "withdraw_info_updated": "Account information has already updated.",
        "withdraw_is_pending": "One withdrawal in progress, please keep waiting.",
        "locked_tips_text1": "Q:Why am I not able to withdraw all total balance?",
        "locked_tips_text2": "A:Because the friends you invited are not active in game, so currently some of your commission is not withdrawable." +
            "<br/>" +
            "If they are active in game, the related commission in your current balance will be converted to your withdrawable balance.",
        "locked_tips_text3": "4 factors determine whether your friends are an active game user:",
        "locked_tips_text4": "1. Daily online time\n" +
            "<br/>" +
            "2. Daily recharge amount" +
            "<br/>" +
            "3. Consecutive login days" +
            "<br/>" +
            "4. Number of games",
        "withdrawal_successful_sent": "The withdrawal has been successful and money will be sent o you within 7 working days.",
        "no_enough_withdrawal_chances": "Not enough withdrawal chances today, please try again tomorrow.",
        "pls_enter_valid_amount": "Please enter valid amount.",
        "enter_amount_invalid": "Please enter an amount within the valid range.",
        "not_have_enough_money": "Sorry, you don't have enough withdrawable amount.",
        "pls_enter_withdraw_amount_first": "Please enter withdrawal amount first.",
        "pls_add_account": "Please add a new account first",
        "mini_withdrawal_amount": "Minimum withdrawal amount",
        "verify_and_get": "Verify",
        "bind_success": "Bind successfully!",
        "bind_failed": "bind failed",
        "request": "Request",
        "enter_mobile_code_tips": "Please enter the mobile verification code you received.",
        "enter_email_code_tips": "Please enter the email verification code you received.",
        "enter_code": "Enter verification code",
        "bind_by": "Bind by:",
        "bind_on_account": "Bind on Account",
        "after_binding_keep_account_tips": "After completing the binding,you can keep your agent account forever.",
        "agent_id": "Agent ID",
        "phone_number": "Phone Number",
        "phone": "Phone",
        "today_affiliates": "Today Affiliates",
        "total_affiliates": "Total Affiliates",
        "invite_now": "Invite now",
        "total": "Total",
        "commission_from_referrals": "Commission from Referrals",
        "commission_from_affiliates": "Commission from Affiliates",
        "no_referral_tips": "You don't have any referrals, please refer a friend first.",
        "the_more_friends_recharge_tips": "The more referrals you refer, the more bonuses you can get!",
        "must_recharge_tips": "Your referral must recharge more than <span style='color: #51B340;font-weight: bold'>₹{client_crazy_recharge}</span>, then both of you will get cash.",
        "your_referral_will_get_tips": "Your referral will get fixed bonus <span style='color: #51B340;font-weight: bold'>₹{client_crazy_bonus}</span>.",
        "you_have_invited": "You've invited:",
        "your_referral_link": "Your Referral Link",
        "account_management": "Account Management",
        "limit": "Limit:",
        "number_of_withdrawals": "Number of Withdrawals:",
        "enter_amount": "Enter Amount",
        "enter_withdrawal_amount": "Enter Withdrawal Amount:(Withdrawal fee ratio {client_with_fee}%)",
        "select_withdraw_account": "Select Withdrawal Account:",
        "withdraw_to": "Withdraw to:",
        "locked_balance": "Locked Amount",
        "withdraw_balance": "Withdrawable",
        "input_cpfid": "Please enter pure numbers (without ./- )",
        "input_mobile": "Please enter your mobile number",
        "input_name": "Please enter your name",
        "input_email": "Please enter your email",
        "input_evp": "Please enter your EVP account",
        "home": "Home",
        "t_and_c": "T&C",
    },
    [LOCALES.HI]: {
        "@@locale": "hi",
        "confirm": "पुष्टि",
        "cancel": "रद्द करना",
        "okay": "ठीक है",
        "click_to_invite": "साझा करने के लिए क्लिक करें:",
        "label_cur_balance": "वर्तमान संतुलन",
        "withdraw": "निकालना",
        "withdrawal": "प्रत्याहरण",
        "wallet_detail": "ब्यौरा",
        "date": "खजूर",
        "type": "Type",
        "commission": "आयोग",
        "label_total_commission": "कुल कमीशन",
        "toast_error_mobile": "गलत फ़ोन प्रारूप!",
        "toast_error_ifsc": "Incorrect IFSC Code!",
        "toast_error_opt": "गलत सत्यापन कोड!",
        "toast_error_email": "गलत ईमेल प्रारूप!",
        "my_team": "मेरी टीम",
        "my_team_today_commission": "आज का आयोग",
        "you_have_got": "आपको मिल गया है:",
        "currency_symbol": "₹",
        "crazy_invitation": "पागल निमंत्रण",
        "tab_earn_money": "पैसे कमाएं",

        "time_box":"समय बॉक्स",
        "daily_box":"दैनिक बॉक्स",
        "back":"Back",
        "finish_get_more":"Finish tasks, get more cash!",
        "you_can_get_free_box_at":"आप 21:30 पर एक मुफ्त बॉक्स प्राप्त कर सकते हैं",
        "you_can_get_free_box_everyday":"आप हर दिन एक मुफ्त बॉक्स प्राप्त कर सकते हैं।",
        "share_your_link_facebook":"फेसबुक पर एक बार अपना लिंक साझा करें",
        "share_your_link_whatsapp":"WhatsApp पर एक बार अपना लिंक साझा करें",
        "invite3_today":"आज 3 दोस्तों को आमंत्रित करें, आप इसे आज 3 बार खत्म कर सकते हैं।",
        "you_recharge_more200":"आज आप ₹200 से अधिक का रिचार्ज करते हैं।",
        "get_recharge_from_friends":"दोस्तों से ₹25 से अधिक का रिचार्ज कमीशन आज ही प्राप्त करें",
        "you_bet_more_than":"आप आज ₹ 2000 से अधिक की शर्त लगाते हैं।",
        "share_by_facebook":"फेसबुक द्वारा साझा करें",
        "share_by_whatsapp":"WhatsApp द्वारा साझा करें",
        "invite3friends":"3 दोस्तों को आमंत्रित करें",
        "recharge_task":"रिचार्जिंग टास्क",
        "get_recharge_commissions":"रिचार्ज कमीशन प्राप्त करें",
        "betting_task":"सट्टेबाजी कार्य",
        "your_cash_rewards":"Your cash rewards",
        "opened":"Opened",
        "free_box":"Free Box",
        "sign_in":"Sign in",
        "more_boxes":"More boxes",
        "cash_in_tomorrow":"Cash in tomorrow’s free box X",
        "open_1_more_box":"Open 1 more box become X",
        "open":"Open",
        "youve_got":"you've got",
        "clear_all_if_stop":"Clear all if stopped",
        "after_get_all_cash":"After you getting all cash, you can withdraw them all.",
        "earn_cash_everyday":"Earn cash everyday, {days} days left",
        "withdraw_all_cash":"Withdraw all cash",
        "sign_in_get_cash":"Sign in and get cash",
        "continue_tomorrow_clear":"Continue tomorrow, clear all if stopped",
        "get_more_cash":"Get more cash",
        "open_suc":"Congratulations!",
        "sign_in_suc":"Sign in successfully!",

        "cash_box":"Cash Box",
        "no_withdraw_channel":"Withdrawal channel maintenance, just one moment.",
        "message_center": "Message Center",
        "invite_earn_everyday": "दोस्तों को बुलाओ, हर रोज पैसा कमाओ!",
        "other": "दूसरा",
        "from_affiliate": "संबद्ध से",
        "from_referrer": "रेफरल से",
        "my_money_title": "दिखाएं कि आपके कमीशन हर रोज कहां से आते हैं!",
        "my_money": "मेरे पैसे",
        "incubating": "इनक्यूबेटिंग...",
        "no_eggs_now": "अब अंडे नहीं,",
        "how_about_invite": "कैसे अधिक दोस्तों को आमंत्रित करने के बारे में?",
        "oops_you_missed": "ओह, आप चूक गए.",
        "choose": "चुनना",
        "pending": "विचाराधीन...",
        "choose_withdrawal_amount": "निकासी राशि चुनें",
        "fee": "शुल्क",
        "new_user_only": "केवल नया उपयोगकर्ता",
        "fee_desc": "निकासी शुल्क भुगतान चैनल द्वारा लिया जाता है, और हम कोई शुल्क नहीं लेते हैं.",
        "benefit_level": "लाभ का स्तर",
        "commission_ratio": "कमीशन का अनुपात",
        "upgrading_cash": "नकदी का उन्नयन कर रहा है",
        "known_more": "और अधिक जानें",
        "points": "अंक",
        "my_level": "मेरा स्तर",
        "vip_valid_days": "संबद्ध मान्य दिन",
        "vip_maximum_affiliate": "अधिकतम कमीशन प्रति संबद्ध",
        "vip_tips1": "नकद छूट केवल तभी मान्य है जब आपका मित्र पंजीकरण के वैध दिनों के भीतर रिचार्ज करता है.",
        "vip_tips2": "प्रत्येक सहयोगी द्वारा छूट नकद इस सीमा से अधिक नहीं हो सकता है.",
        "tips": "युक्तियाँ",
        "input_ifsc": "कृपया IFSC कोड दर्ज करें",
        "input_upi": "कृपया हमारा यूपीआई दर्ज करें",
        "input_bank_account": "कृपया अपना बैंक खाता दर्ज करें",
        "level1_name": "कांस्य विशेषाधिकार",
        "level2_name": "रजत विशेषाधिकार",
        "level3_name": "सोने का विशेषाधिकार",
        "level4_name": "प्लैटिनम विशेषाधिकार",
        "level5_name": "डायमंड विशेषाधिकार",
        "total_referrals": "कुल रेफरल",
        "vip": "वीआईपी",
        "close": "बंद करना",
        "break_me": "मुझे तोड़ दो।",
        "incubating_please_patient": "इनक्यूबेटिंग, कृपया धैर्य रखें ...",
        "no_record_last_7": "पिछले 7 दिनों में कोई रिकॉर्ड नहीं",
        "invite_1_friends_1": "1 मित्रों को आमंत्रित करें = 1",
        "invite_2_friends_2": "2 दोस्तों को आमंत्रित करें = 2",
        "others": "दूसरों",
        "crazy_invitation_tips": "अब आप एक एजेंट बन गए हैं, दोस्तों को अधिक मुफ्त नकदी कमाने के लिए आमंत्रित करें! यहाँ आप के लिए नौसिखिया इनाम है.",
        "welcome": "स्वागत है!",
        "egg_how_to_play_title": "गोल्डन एग क्या है?",
        "egg_how_to_play_text1": "गोल्डन अंडा कैसे काम करता है?",
        "egg_how_to_play_text2": "गोल्डन एग आपको स्वचालित रूप से <span style='color: #1CAA28'>₹{client_egg_hour}</span> प्रति घंटा कमाने में मदद करता है। जब अंडे में <span style='color: #1CAA28'>₹{client_egg_total}</span> एकत्र किया जाता है, तो आप सभी नकदी निकाल सकते हैं।",
        "egg_how_to_play_text3": "कैसे एक सुनहरा अंडा पाने के लिए?",
        "egg_how_to_play_text4": "आप 2 दोस्तों को सफलतापूर्वक आमंत्रित करने के बाद एक अंडा प्राप्त कर सकते हैं। इसके बाद, आप हर बार जब आप 1 दोस्त को आमंत्रित करते हैं तो आप एक सुनहरा अंडा प्राप्त कर सकते हैं।",
        "egg_how_to_play_text5": "Q:Why there are two options?",
        "egg_how_to_play_text6": "A:If you choose 1x, your chance of getting the reward is 100%, if you choose 3x, you have a chance of getting 3x the reward or losing all the rewards.",
        "convidar_agora": "अभी आमंत्रित करें",
        "break": "तोड़ना",
        "how_to_play": "कैसे खेलने के लिए?",
        "your_eggs": "आपके अंडे:",
        "golden_egg": "सुनहरे अंडे",
        "wallet_detail_type_0": "दूसरों",
        "wallet_detail_type_1": "निकालना",
        "wallet_detail_type_2": "दूसरों",
        "wallet_detail_type_3": "दूसरों",
        "wallet_detail_type_4": "दूसरों",
        "wallet_detail_type_5": "वापस लेना विफल रहा",
        "wallet_detail_type_6": "दूसरों",
        "wallet_detail_type_7": "दूसरों",
        "wallet_detail_type_8": "दूसरों",
        "wallet_detail_type_9": "पागल निमंत्रण आयोग",
        "wallet_detail_type_10": "रिचार्ज कमीशन",
        "wallet_detail_type_11": "आयोग पंजीकृत करें",
        "wallet_detail_type_12": "आपका स्वागत है बोनस",
        "wallet_detail_type_13": "सुनहरे अंडे",
        "locked": "अवरोधित",
        "recent_7_days": "- हाल ही में 7 दिन -",
        "referrals_recharged": "रेफरल रिचार्ज किए गए:",
        "cashback_tips": "कैशबैक आपके निकासी खाते में इंस्टाली जोड़ देगा, जितना अधिक आपके दोस्त रिचार्ज करेंगे, उतना ही आपको मिलेगा",
        "recharge_cashback": "रिचार्ज करें कैशबैक",
        "sign_up_in": "साइन अप करें/",
        "agent_id_copied": "एजेंट ID की सफलतापूर्वक प्रतिलिपि बनाई गई.",
        "failed": "असफल",
        "copy": "प्रतिलिपि",
        "copy_and_send": "प्रतिलिपि बनाएँ और भेजें",
        "copied_link_suc": "सफलतापूर्वक कॉपी किया गया, अब आप इसे अपने दोस्तों के साथ साझा कर सकते हैं.",
        "verification_code_sent_suc": "सत्यापन कोड भेजा गया है, कृपया इसे अपने ईमेल या मोबाइल फोन में जांचें।",
        "withdraw_info_updated": "खाता जानकारी पहले ही अद्यतन की जा चुकी है.",
        "withdraw_is_pending": "एक निकासी प्रगति पर है, कृपया प्रतीक्षा करते रहें.",
        "locked_tips_text1": "Q:मैं सभी कुल शेष राशि को वापस लेने में सक्षम क्यों नहीं हूं?",
        "locked_tips_text2": "A:क्योंकि आपके द्वारा आमंत्रित किए गए मित्र खेल में सक्रिय नहीं हैं, इसलिए वर्तमान में आपका कुछ कमीशन वापस लेने योग्य नहीं है।" +
            "<br/>" +
            "यदि वे खेल में सक्रिय हैं, तो आपके वर्तमान शेष में संबंधित कमीशन आपके वापस लेने योग्य शेष में परिवर्तित हो जाएगा।",
        "locked_tips_text3": "4 कारक निर्धारित करते हैं कि आपके मित्र एक सक्रिय गेम उपयोगकर्ता हैं या नहीं:",
        "locked_tips_text4": "1. दैनिक ऑनलाइन समय" +
            "<br/>" +
            "2. डेली रिचार्ज अमाउंट" +
            "<br/>" +
            "3. लगातार लॉगिन दिन" +
            "<br/>" +
            "4. खेलों की संख्या",
        "withdrawal_successful_sent": "निकासी सफल रही है और 7 कार्य दिवसों के भीतर आपको पैसा भेज दिया जाएगा।",
        "no_enough_withdrawal_chances": "आज पर्याप्त निकासी की संभावना नहीं है, कृपया कल फिर से प्रयास करें।",
        "pls_enter_valid_amount": "कृपया मान्य राशि दर्ज करें.",
        "enter_amount_invalid": "कृपया मान्य श्रेणी के भीतर कोई राशि दर्ज करें.",
        "not_have_enough_money": "क्षमा करें, आपके पास पर्याप्त निकासी योग्य राशि नहीं है.",
        "pls_enter_withdraw_amount_first": "कृपया पहले निकासी राशि दर्ज करें.",
        "pls_add_account": "कृपया पहले एक नया खाता जोड़ें",
        "mini_withdrawal_amount": "न्यूनतम निकासी राशि",
        "verify_and_get": "जाँचना",
        "bind_success": "सफलता को बाध्य करें",
        "bind_failed": "बाइंड विफल रहा",
        "request": "निवेदन",
        "enter_mobile_code_tips": "कृपया आपको प्राप्त मोबाइल सत्यापन कोड दर्ज करें।",
        "enter_email_code_tips": "कृपया आपके द्वारा प्राप्त ईमेल सत्यापन कोड दर्ज करें.",
        "enter_code": "वेरिफिकेशन कोड एंटर करें",
        "bind_by": "द्वारा बाँधना:",
        "bind_on_account": "खाते पर बाइंड करें",
        "after_binding_keep_account_tips": "बाइंडिंग को पूरा करने के बाद, आप अपने एजेंट खाते को हमेशा के लिए रख सकते हैं.",
        "agent_id": "एजेंट आईडी",
        "phone_number": "फोन संख्या",
        "phone": "फ़ोन",
        "today_affiliates": "आज संबद्ध",
        "total_affiliates": "कुल सहयोगी संगठन",
        "invite_now": "अभी आमंत्रित करें",
        "total": "कुल",
        "commission_from_referrals": "रेफरल से कमीशन",
        "commission_from_affiliates": "संबद्ध संगठनों से आयोग",
        "no_referral_tips": "आपके पास कोई रेफरल नहीं है, कृपया पहले एक दोस्त को देखें।",
        "the_more_friends_recharge_tips": "जितना अधिक रेफरल आप संदर्भित करते हैं, उतना अधिक बोनस आप प्राप्त कर सकते हैं!",
        "must_recharge_tips": "आपके रेफरल को <span style='color: #51B340; font-weight: bold' > ₹ {client_crazy_recharge}</span>, तो आप दोनों को नकद मिलेगा।",
        "your_referral_will_get_tips": "आपके रेफरल को निश्चित बोनस मिलेगा <span style='color: #51B340; font-weight: bold'> ₹ {client_crazy_bonus}</span>.",
        "you_have_invited": "आपने आमंत्रित किया है:",
        "your_referral_link": "आपका रेफरल लिंक",
        "account_management": "खाता प्रबंधन",
        "limit": "सीमा:",
        "number_of_withdrawals": "निकासी की संख्या:",
        "enter_amount": "राशि दर्ज करें",
        "enter_withdrawal_amount": "निकासी राशि दर्ज करें: (निकासी शुल्क अनुपात {client_with_fee}%)",
        "select_withdraw_account": "निकासी खाता चुनें:",
        "withdraw_to": "को वापस ले लो:",
        "locked_balance": "अवरोधित राशि",
        "withdraw_balance": "वापस लेने योग्य",
        "input_cpfid": "कृपया शुद्ध संख्याएँ दर्ज करें (./- के बिना)",
        "input_mobile": "कृपया अपना मोबाइल नंबर दर्ज करें",
        "input_name": "कृपया अपना नाम दर्ज करें",
        "input_email": "कृपया अपना ईमेल दर्ज करें",
        "input_evp": "कृपया अपना ईवीपी खाता दर्ज करें",
        "home": "घर",
        "t_and_c": "टी एंड सी"
    }
}
