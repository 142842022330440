import './TabTop.css';

export interface TabTopProp {
    idx: number;
}
export default function TabTop (prop: TabTopProp) {
    let selectedStyle = {
        paddingBottom: '5px',
        borderBottom: '2px solid #FFDA00',
        color: '#FFDA00',
    };
    return <div className={"Header"}>
        <img className={"HeaderIcon"} alt={'lucky 3patti'} src={require('../../../assets/img/icon1.png')}></img>
        <div className={"HeaderTabs"}>
            <div className={"HeaderTab"} style={prop.idx === 0 ? selectedStyle : {}} onClick={()=>{
                document.title = 'Home';
                window.changeTab(0);
            }}>HOME</div>
            <div className={"HeaderTab"} style={prop.idx === 1 ? selectedStyle : {}} onClick={()=>{
                document.title = 'Hot Games';
                window.changeTab(1);
            }}>HOT GAMES</div>
            <div className={"HeaderTab"} style={prop.idx === 2 ? selectedStyle : {}} onClick={()=>{
                document.title = 'Download';
                window.changeTab(2);
            }}>DOWNLOAD</div>
            <div className={"HeaderTab"} style={prop.idx === 3 ? selectedStyle : {}} onClick={()=>{
                document.title = 'Blog';
                if (window.setPageIdx) {
                    window.setPageIdx(-1);
                }
                window.changeTab(3);
            }}>BLOG</div>
            <div className={"HeaderTab"} style={prop.idx === 4 ? selectedStyle : {}} onClick={()=>{
                document.title = 'FAQ';
                window.changeTab(4);
            }}>FAQ</div>
        </div>
    </div>
}
