import './TabFaq.css';
import TabBottom from "../bottom/TabBottom";
import TabTop from "../top/TabTop";
import Helmet from "react-helmet";

export default function TabFaq() {

    return <div className={"TabFaq"}>

        <Helmet>
            <meta name={`description`} content={`FAQ about TeenPatti Lucky, you can find them here.`} />
            <title>FAQ│TeenPatti Lucky</title>
        </Helmet>

        <TabTop idx={4}/>

        <div className={'FaqTitle'}/>

        <h2 className={'FaqItemTitle'}>1)What is TeenPatti Lucky?</h2>

        <article className={'FaqItemContent'}>TeenPatti Lucky is India`s best free online games.More hot games.more rewards!Get free chips and win real cash!Free for limited time!Go download it now!</article>

        <h2 className={'FaqItemTitle'}>2)How to earn cash with Teen Patti Lucky games?</h2>

        <article className={'FaqItemContent'}>First,you should download TeenPatti Lucky to get free money,then you can show your skills to win more and invite friends to get more rewards.</article>

        <h2 className={'FaqItemTitle'}>3)How can I contact Teen Patti Lucky Games Team?</h2>

        <article className={'FaqItemContent'}>Do not hesitate to contact us through email at luckyteenpatti@yahoo.com</article>

        <TabBottom/>
    </div>
}
