import {getItem} from "../util/Common";
import {LOCALES} from "../i18n/locales";
import {messages} from "../i18n/messages";
import {IntlProvider} from "react-intl";
import {useState} from "react";
import Home from "./Home";

export function HomeWrapper() {
    // 0英语，1印地语，2印尼语，3葡萄牙语
    const localeStr: string = getItem("lanCode") ?? LOCALES.HI;
    window.languageCode = localeStr;

    const [locale, setLocale] = useState(localeStr);

    return <IntlProvider messages={messages[locale]} locale={locale} defaultLocale={LOCALES.HI}>
        <Home setLocale={setLocale}/>
    </IntlProvider>
}
